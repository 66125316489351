import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home,
  // },
  {
    path: '/sign-preview',
    name: 'SignPreview',
    component: () => import('../views/sign/preview'),
  },
  {
    path: '/sign/:id',
    name: 'Sign',
    component: () => import('../views/sign'),
  },
  {
    path: '/sign-panel',
    name: 'SignPanel',
    component: () => import('../views/sign/panel'),
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
