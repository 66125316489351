import Vue from 'vue';
import Vuex from 'vuex';
import contract from './modules/contract'

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    contract
  },
});

export default store
