// 按需全局引入 vant组件
import Vue from 'vue';

import {
  Button, List, Cell, CellGroup, Tabbar, TabbarItem, Tab, Tabs, Field, NumberKeyboard,
  Toast, NavBar, Icon, Swipe, SwipeItem, Lazyload, Tag, Card, Divider, Uploader, Popup,
  Picker, Col, Row, Overlay, Image, DatetimePicker, Dialog, Skeleton, ShareSheet, Checkbox,
  CheckboxGroup, Loading, Sku, Search, Sticky, Calendar, Notify, PasswordInput, CountDown
} from 'vant';

Vue.use(Button);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(List);
Vue.use(Tabbar).use(TabbarItem);
Vue.use(Tab).use(Tabs);
Vue.use(Field);
Vue.use(NumberKeyboard);
Vue.use(Toast);
Vue.use(NavBar);
Vue.use(Icon);
Vue.use(Swipe).use(SwipeItem).use(Lazyload);
Vue.use(Tag);
Vue.use(Card);
Vue.use(Divider);
Vue.use(Uploader);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Col);
Vue.use(Row);
Vue.use(Overlay);
Vue.use(Image);
Vue.use(DatetimePicker);
Vue.use(Dialog);
Vue.use(Skeleton);
Vue.use(ShareSheet);
Vue.use(Checkbox).use(CheckboxGroup);
Vue.use(Loading);
Vue.use(Sku);
Vue.use(Search);
Vue.use(Sticky);
Vue.use(Calendar);
Vue.use(Notify);
Vue.use(PasswordInput);
Vue.use(CountDown);
