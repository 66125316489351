import { reqContractStatus } from '@/api/contract'

const state = {
    contractId: '',
    contract: {
        status: 0,// 1. 待签署 2. 已完成 3. 已作废
        templateViewUrl: '',
        signer: '',
        name: '',
        email:'',
    }
}

const mutations = {
    setContract(state, contract) {
        state.contract = contract
    },
    setContractId(state, id) {
        state.contractId = id
    }
}

const actions = {
    remoteGetContract({ commit }, contractId) {
        reqContractStatus(contractId).then(res => {
            commit('setContract', res.contract)
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}