import request from '@/utils/request'

export function reqContractStatus(id) {
    return request({
        url: `/contract/status/${id}`,
        method: 'get',
    })
}

export function reqSendMail(id) {
    return request({
        url: `/contract/mail/send/${id}`,
        method: 'get',
    })
}
