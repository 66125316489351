import axios from 'axios'
import { Toast } from 'vant'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 30000
})

// msg: "java.lang.NullPointerException  mark: null"
// ret: false
// total: 0
// ver: 0

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.ret == false) {
      Toast.fail({
        message: res.errMsg,
        duration: 3 * 1000
      })
      return Promise.reject(new Error(res.errMsg || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    Toast.fail({
      message: error.message,
      duration: 3 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
